import React from 'react'

// components
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import SEO from '../../components/SEO/seo'
import Layout from '../../components/Layout/layout'
import Section from '../../components/Section/section'
import GetStarted from  '../../components/GetStarted/getStarted'

// utils
import { RUSH_DIGITAL_CASE } from '../../utils/article/rushDigitalCase'
import { CUSTOMER_LOGOS } from '../../utils/content/rpCustomerLogos'
import { WHY_LEARNING_CURVE } from '../../utils/content/whyLearningCurve'

class RushPage extends React.Component {

    __renderWhyLearningCurve () {
        return <Section animation={false} background={'bg-salmon'} spacing='py-4'>
            <Col lg={12}>
                <h2 className='h1 text-center mt-0 mt-sm-4' data-sal='slide-up' data-sal-duration='2000' data-sal-delay="300" data-sal-easing="ease-out-quart">Why pick Learning Curve?</h2>
                <Row>
                    {
                        WHY_LEARNING_CURVE.map((data, index) => (
                            <>
                            <Col lg={6} key={index} className='d-flex flex-column mt-5' data-sal='slide-right' data-sal-duration='2000' data-sal-delay={index==0?'300': index==1?'500': '700'} data-sal-easing="ease-out-quart">
                                <h3>{data.title}</h3>
                                <p>{data.description}</p>
                            </Col>
                            <hr className='d-block d-sm-none w-100'/>
                            </>
                        ))
                    }
                </Row>
            </Col>
        </Section>
    }

    __renderCustomerLogos (spacing, style) {
        return <Section background={'bg-peach'} spacing={spacing ? spacing : 'py-3'} containerClasses={style ? style : ''}>
            <Col lg={12}>
                <h2 className='h4 text-center' data-sal='slide-up' data-sal-duration='2000' data-sal-delay="300" data-sal-easing="ease-out-quart">Trusted by domain experts across the world</h2>
                <Row className='justify-content-center'>
                    {
                        CUSTOMER_LOGOS.map((row, index) => (
                            <Col lg={12} key={index}>
                                <Row className='justify-content-center my-0 '>
                                {
                                    row.map((logo, index) => (
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={logo.link} 
                                            className={`${logo.space ? 'col-3 col-md-1 col-lg-1' : 'col-6 col-md-3 col-lg-2'} ${logo.p ? '' : 'd-none d-md-flex  my-sm-0'} my-4 d-flex justify-content-center align-items-center`}>
                                            <img src={logo.image} alt={logo.alt} key={index}/>
                                        </a>
                                    ))
                                }
                                </Row>
                            </Col>
                        ))
                    }
                </Row>
            </Col>
        </Section>
    }


    render(){
        return <>
            <SEO title='Rush Digital' description={'A 100% done-for-you course creation service. We\'ll extract your knowledge and create a course for you in 30 days.'}/>
            <Layout classes='fw-landing-page'>
                {
                    RUSH_DIGITAL_CASE.map((section, index) => (
                        <Section
                            key={index} 
                            {...section} />
                    ))
                }
                <GetStarted background={'bg-peach'} description={'We offer a done-for-you course creation service that will have your own online course like Rush Digital\'s fully completed and ready to sell in just 30 days — guaranteed. '}/>
                {this.__renderWhyLearningCurve()}
                {this.__renderCustomerLogos()}
            </Layout>
        </>
    }
}

export default RushPage
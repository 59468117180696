export const RUSH_DIGITAL_CASE = [
    {
        superTitle: 'Case Study: Rush Digital  · 10 min read time',
        title: 'Teaching intellectual property in a practical way to salespeople and software developers',
        superImage: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1625112025/Rush_Logo_libyhb.svg',
        superImageAlt: 'Rush Digital Logo',
        superImageHeight: '56',
        superImageClass: 'mb-3',
        spacing: 'pt-2 pt-sm-5 pb-4',
        background: 'bg-salmon',
        breadcrumbs: [
            {
                name: 'Case Studies',
                destination: '/cases'
            },
            {
                name: 'Rush Digital',
                destination: '/cases/rush-digital',
                last: true
            }
        ],
        content: [
            '<em>How do you make compliance training interesting and engaging?</em>',
        ],
        contentImage: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1625111361/pexels-startup-stock-photos-7070_zpvszt.jpg',
        contentImageAlt: 'Office Space',
        contentImageClass: 'w-100 h-100'
    },
    {
        title: 'The problem',
        background: 'bg-white',
        spacing: 'pt-3 pb-2',
        listContent:[
            'Pavan, the managing director of Rush (a digital agency turned studio) was scaling incredibly quickly. He was hiring almost every week and had to move offices twice in the space of a year. ',
            'With the rapid increase in team size, Pavan was looking for a tool to ensure that his teams were up to date with how to deal with intellectual property — especially when agency work meant that the intellectual property coming out of it would be owned by the client. Products that Rush built however would mean that it could be used in other internal projects. ',
            'Pavan wanted something light and easy to use. It also had to be mobile friendly with an analytics dashboard.'
        ],
        listItemClasses: 'mb-4'
    },
    {
        title: 'The Challenge',
        background: 'bg-white',
        spacing: 'pt-0 pb-2',
        listContent:[
            'Build an intellectual property course that is practical for Rush (for both software developers and salespeople) for their day-to-day interactions with stakeholders',
            'The resource needed to start from the absolute basics (\'What is intellectual property\') all the way to being able to critically discuss intellectual property in contract negotiations and comprehend exotic software license packages — usually from different jurisdictions. ',
            'The resource needed to be mobile friendly and not be cumbersome on teams. The courses needed to take less than a couple hours to complete. '
        ],
        listItemClasses: 'mb-4',
    },
    {
        title: 'What we did',
        background: 'bg-white',
        spacing: 'pt-0 pb-2',
        listContent:[
            `
                <p>Spent time to really understand what each team member needed to know about intellectual property by taking a human-centered design approach.</p>
                <ul>
                    <li>This included going through a process of understanding the language and context specific to each type of team player (salespeople and software developers).</li>
                    <li>Ensured that there was a common language that was easily understood by all team members and used that as our starting point.</li>
                    <li>Approached the project in an agile methodology with a continuous improvement/continuous development mindset. We tested with users every week.</li>
                    <li>Used interesting case studies and pop culture references to ensure high levels of engagement (i.e We wrote a lesson on if memes infringe on copyright.)</li>
                
                </ul>
            `,
            'Researched other compliance applications and value propositions.',
            'Collaborated with intellectual property lawyers and subject matter experts — and researched the latest legal literature.',

        ],
        listItemClasses: 'mb-4',
    },
    {
        title: 'The outcome',
        background: 'bg-white',
        spacing: 'pt-0 pb-4',
        listContent:[
            'Created a practical intellectual property course for both salespeople and software developers that had an average completion time of around 2.5 hours.',
            'Helped design an intellectual property register. ',
            'Contributed ideas to gamify the process of identifying and logging intellectual property and the corresponding owner for software developers.',
        ],
        listItemClasses: 'mb-4',
    },
    {
        background: 'bg-cream',
        spacing: 'pt-3 pb-5',
        title: 'How do you make compliance training interesting and engaging?',
        content: [
            'This was a question we asked ourselves when we were approached by Rush Digital to create an intellectual property course. When we first met Pavan, the CEO of Rush Digital, the team was relatively small with about 20 developers and five salespeople. As we started talking about requirements and deliverables before signing a contract, Rush Digital entered its growth phase with hiring new developers and sales people weekly. We came to quickly understand that the training not only had to be for the current team, but also part of an onboarding process.',
            'The content was simple enough. Create an intellectual property course for both software developers and salespeople. Unlike Holly\'s course (a legal course where much of the information was not written down), there was a lot of literature on intellectual property and engaging with intellectual property lawyers and legal cases was relatively straightforward.',
            'The bigger challenge was ensuring that team members saw value in the content and engaged with it in a way that would protect the business and brand. ',
            'So how did we go about solving for this? Before doing any instructional design, we spent time talking to the salespeople and software developers. We asked the obvious questions such as \'What do you know about intellectual property?\' but also asked questions like \'What TV shows do you watch?\' and \'Who was your favourite teacher in school and why?\' The answers to these questions helped us to develop a better understanding of how our content should be framed.',
            'We also spent time to better understand the language that was being used in the office. Each organisation has its own culture and we wanted to make sure that we could capture this when building out the training. Early on in the project development, we decided on taking an agile approach. In terms of instructional design, this meant that we worked with a small group (about five people) where we would publish modules every week and would then come into the office and talk to them about what they liked and disliked about the learning, before it being released to everyone else. This ensured that we would be building a course that everyone resonated with.',
            '<hr class="my-5"/>',
            'In terms of a learning approach, we decided to go with a bite-sized question first approach. Each lesson was only five questions long and centered around a story that involved popular culture or something nostalgic. This meant we could provide corrective feedback for each question so the learning could be as efficient as possible. Every single question was backed up with detailed explanations to ensure that people had a good understanding of not only what the law is but how and why it applies. We wanted the team to be able to think critically about the law especially when things were not so obvious. We also created notes so that people could refer to the laws and cases to make informed decisions about the questions that they were presented.',
            '<hr class="my-5"/>',
            'By taking a human-centered design approach, it meant that we could build out really interesting content. In the course, there are case studies involving Age of Mythology (a popular computer game from the early 2000s), asked interesting questions such as if memes infringe on copyright law, and even created hypothetical scenarios involving some of Rush Digital\'s products. ',
            '<hr class="my-5"/>',
            'Once we developed the course, we also decided to create a weekly email sequence to discuss other fascinating legal cases involving pop-culture or thought experiments. We even created a phishing test to see if people would sign up to a platform and disclose any intellectual property. We then engaged with managers on building out an intellectual property register and suggested ways that this could be gamified. All this meant an incredibly high completion rate (~85%) of the training. ',
            'We believe that for training to be effective it needs to be so interesting that people want to talk about it at a party. By going through a human-centered design approach to compliance and instructional design, we made the training fun, engaging and powerful.'
        ],
        image:'https://res.cloudinary.com/dqpij5loy/image/upload/q_auto/v1611968005/Rio_tinto_river_CarolStoker_NASA_Ames_Research_Center_rtw0gp.jpg',
        imageAlt: 'Acid mine drainage can turn waterways orange to red',
        imageCaption: 'Rio Tinto in Spain — Acid mine drainage can turn waterways orange to red'
    }
]